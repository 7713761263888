import React from 'react';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import PageBanner from '../components/layouts/page-banner';
import { Container } from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';

const TermsOfService: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Terms of Service"
        description="Marvel &amp; Snap reserves the right to update and change the Terms of Service by posting updates and changes to the Marvel &amp; Snap website."
      />
      <PageBanner heading="Terms of Service" />
      <LightMode>
        <VerticalPadding>
          <Container maxWidth="lg" style={{ lineHeight: 1.77 }}>
            <p>
              <small>Last updated on July 1, 2024</small>
            </p>
            <p>
              The Customer acknowledges that the Terms of Service of this
              Agreement have been made available to the Customer and have been
              reviewed by the Customer prior to the Customer requesting the
              performance of services by Marvel &amp; Snap. The Customer further
              acknowledges that submission of the Order constitutes acceptance
              of this Agreement and will bind the Customer to all of the Terms
              of Service hereof.
            </p>
            <p>
              Marvel &amp; Snap reserves the right to update and change the
              Terms of Service by posting updates and changes to the Marvel
              &amp; Snap website. If Marvel &amp; Snap makes any material
              changes, an e-mail will be sent to the Customer at the e-mail
              address listed on the Order. Any changes to the Terms of Service
              will be effective immediately for new Customers and will be
              effective thirty (30) days after posting such changes to the
              Marvel &amp; Snap website for existing Customers, provided that
              any material changes shall be effective for existing Customers
              upon the earlier of thirty (30) days after posting such changes on
              the Marvel &amp; Snap website or thirty (30) days after dispatch
              of an e-mail notice of such changes to such Customers. You are
              advised to check the Terms of Service from time to time for any
              updates or changes that may impact you and if you do not accept
              such amendments, you must cease using the Services.
            </p>
            <p>
              In consideration of the mutual promises and covenants made herein,
              the parties agree as follows:
            </p>
            <h2>1. Fees and Services</h2>
            <p>
              Subject to the terms and conditions of this Agreement, Marvel
              &amp; Snap will provide the Services (the &#8220;<b>Services</b>
              &#8220;) specified in the Order (the “<b>Order</b>”) attached as
              Exhibit A hereto and incorporated into this Agreement by
              reference. All our WebOps plans are available on monthly prepaid,
              non-refundable contracts. All contracts will automatically renew
              for the same term using the payment method on file unless the
              client changes or discontinues the service.
            </p>
            <h2>2. Ownership; Rights; Proprietary Information; Publicity</h2>
            <p>
              The Customer shall own all right, title and interest for work
              completed during each Billing Period (the “<b>Billing Period</b>”)
              after invoice for that Billing Period is paid in full to Marvel
              &amp; Snap. The Billing Period starts from the first day of the
              month and ends on the last day of the month for the duration of
              the working Agreement.
            </p>
            <h2>3. Service Levels</h2>
            <p>
              The following sections provide relevant details on service
              availability, support of service requests and related components.
            </p>
            <h3>3.1. Service Availability</h3>
            <p>
              Coverage parameters specific to the Services specified in the
              Order are as follows:
            </p>
            <ul>
              <li>
                Telephone support: 9:00 A.M. to 5:00 P.M. EST Monday &#8211;
                Friday
              </li>
              <li>
                Calls received out of office hours will be forwarded to a mobile
                phone and best efforts will be made to answer / action the call,
                however there will be a backup answer phone service
              </li>
              <li>
                Email support: Monitored 9:00 A.M. to 5:00 P.M. EST Monday
                &#8211; Friday
              </li>
              <li>
                Emails received outside of office hours will be collected,
                however no action can be guaranteed until the following business
                day
              </li>
              <li>
                Video conference meetings guaranteed within 72 hours during the
                business week
              </li>
            </ul>
            <h3>3.2. Service Requests</h3>
            <p>
              In support of the Services outlined in the Order, Marvel &amp;
              Snap will respond to service related requests submitted by the
              Customer within the following time frames (during regular business
              hours):
            </p>
            <ul>
              <li>
                Within 6 hours for support requests under our Hosting plan.
              </li>
              <li>
                Within 4 hours for support requests under our Small Team plan.
              </li>
              <li>
                Within 2 hours for support requests under our Business plan.
              </li>
              <li>
                Within 1 hour for support requests under our Enterprise plan.
              </li>
              <li>
                Support assistance will be provided in-line with the above
                timescales dependent on the complexity of the support request.
              </li>
            </ul>
            <h2>4. Warranty</h2>
            <p>
              Subject to the qualifications and limitations contained herein,
              Marvel &amp; Snap warrants that the Services will, in all material
              respects, conform to the description of Services provided in the
              Order. Except as set expressly forth in this Agreement, Marvel
              &amp; Snap and its contractors hereby disclaim all warranties of
              any kind, expressed or implied, including, without limitation, the
              warranties of merchantability, fitness for a particular purpose,
              and non-infringement. Neither Marvel &amp; Snap nor its
              contractors makes any warranty that the Services or the Customer’s
              website(s) will be error free, free of viruses or other harmful
              components, or that access thereto will be continuous or
              uninterrupted. Marvel &amp; Snap does not represent or warrant but
              will make commercially reasonable efforts to ensure that the
              services are uninterrupted or error-free, that defects will be
              corrected, or that any servers used for Marvel &amp; Snap’s
              Hosting Services will be free of viruses or other harmful
              components. You understand that the use of the Services is at your
              own discretion and risk.
            </p>
            <h2>5. Termination</h2>
            <p>
              The terms of this Agreement shall commence on the Service Start
              Date listed in the Order and shall continue until the Service has
              been terminated, as expressly set forth herein. Marvel &amp; Snap
              may terminate the Service at any time: (i) if the Customer
              materially breaches any provision of this Agreement; or (ii) if
              the Customer ceases to do business, or otherwise terminate their
              business operations without a successor; or (iii) if the Customer
              becomes insolvent or seeks protection under any bankruptcy,
              receivership, trust deed, creditors arrangement, composition or
              comparable proceeding; or (iv) if the Customer is in default of
              payment; or (v) if Marvel &amp; Snap is required to do so by Law
              (for example, where the provision of the Services to the Customer
              is, or becomes, unlawful). Notwithstanding the preceding, either
              party may terminate this Agreement at will upon thirty (30) days
              written notice to the other party.
            </p>
            <h2>6. Limitation of Liability</h2>
            <p>
              Neither party shall be liable for any special, punitive, indirect,
              or consequential losses or damages, including loss of profit, even
              if advised of the possibility thereof, for any claim arising under
              this Agreement, regardless of the cause of action. EXCEPT FOR
              LIABILITY OR BREACH RELATED TO BODILY INJURY, REAL OR TANGIBLE
              PROPERTY DAMAGE, GROSS NEGLIGENCE OR WILLFUL MISCONDUCT NEITHER
              PARTY SHALL BE LIABLE TO THE OTHER PARTY FOR AN AMOUNT GREATER
              THAN THE AMOUNT PAID BY THE CUSTOMER TO MARVEL &amp; SNAP FOR THE
              SERVICES IN THE THREE (3) MONTHS PRIOR TO NOTICE OF THE CLAIM.
            </p>
            <h2>7. Indemnity</h2>
            <p>
              The Customer agrees to indemnify and save harmless Marvel &amp;
              Snap and its directors, officers, and employees, from third party
              liabilities, costs and expenses, including reasonable legal costs,
              to the extent directly arising out of the Customer’s material
              breach of this Agreement by the Customer or its users/clients.
              Marvel &amp; Snap agrees to indemnify and save harmless the
              Customer and its directors, officers, and employees, from third
              party liabilities, costs and expenses, including reasonable legal
              costs, to the extent directly attributable to Marvel &amp; Snap
              material breach of this Agreement. If a party seeks
              indemnification hereunder, that party will promptly notify the
              other party, provide the other party reasonable, non-monetary
              assistance, and sole control over any litigation or settlement
              proceedings.
            </p>
            <h2>8. Relationship of Parties</h2>
            <p>
              No agency, partnership, joint venture, or employment relationship
              is created by this Agreement and neither party has the power to
              bind the other party.
            </p>
            <h2>9. Confidentiality of Client Information</h2>
            <p>
              Marvel &amp; Snap and Customer acknowledge that during the course
              of normal account administration, information which is
              confidential to Customer may be accessed by Marvel &amp; Snap.
              Marvel &amp; Snap agrees that such Confidential Information is the
              exclusive property of Customer and agrees to treat confidentially
              all such information and, unless legally compelled, shall not
              disclose any such information to any third party during the term
              of this Agreement. Except where demanded by law, upon termination
              of this Agreement Marvel &amp; Snap will destroy all records,
              software, data and other information and media that it may have in
              its possession. This does not include Customer records, such as
              contact and billing information, required for administrative
              purposes by Marvel &amp; Snap.
            </p>
            <h2>10. General</h2>
            <p>
              (i) This Agreement, including any and all documents incorporated
              herein by reference, constitutes the entire Agreement between the
              Customer and Marvel &amp; Snap relating to provision of Services.
              This Agreement supersedes all prior agreements, understandings,
              negotiations and discussions, whether oral or written, between
              Marvel &amp; Snap and the Customer (ii) Headings are used
              throughout this Agreement for convenience only and no provision,
              term or condition of this Agreement shall be construed by
              reference to any heading of this Agreement. (iii) Either party’s
              failure to insist on or enforce strict performance of any
              provision of this Agreement shall not be construed as a waiver of
              any provision, term, condition or right of that party contained in
              this Agreement. (iv) If any of the terms, conditions or provisions
              of this Agreement are determined to be void, invalid or otherwise
              unenforceable by any court of competent jurisdiction, the same
              shall be severable from the rest of this Agreement and such
              determination shall not affect the remaining provisions contained
              in this Agreement. (v) This Agreement shall be governed by and
              construed in accordance with the laws of the Province of Ontario
              and the federal laws of Canada applicable therein and the courts
              of such province shall have exclusive jurisdiction to adjudicate
              any claim or dispute relating to this Agreement. (vi) Neither this
              Agreement nor any rights hereunder may be assigned by either
              party, without the prior written consent of the other party, such
              consent not to be unreasonably withheld. (vii) Customer may not
              make any re-sale of any of the Services provided hereunder without
              the express prior written consent of Marvel &amp; Snap. (viii)
              Marvel &amp; Snap shall not be liable for any delay or failure in
              performance of Services due to events beyond Marvel &amp; Snap’s
              direct control, including war, riot, embargoes, strikes,
              casualties, accidents, fire, earthquake, flood, acts of God,
              government intervention or action, supplier or vendor failure
              (“Force Majeure Events”).
            </p>
          </Container>
        </VerticalPadding>
      </LightMode>
    </Layout>
  );
};

export default TermsOfService;
